<template>
  <section class="section p-5" v-if="!hideHtml">
    <div>
      <app-logo class="my-5"/>
      
      <h5 class="title is-5 pt-5">{{ $t('account.createUser') }}</h5>
      
      <b-steps
          v-model="activeStep"
          :animated="true"
          :rounded="true"
          :has-navigation="false"
          label-position="bottom"
          mobile-mode="compact"
          size="is-small">

        <b-step-item>

          <div class="fill-strech hmt-5">
           
            <div v-if="!$store.state.setup.registration.hideAvatarSelector">

              <p>{{$t('account.selectAvatarAndName')}}</p>

              <!-- avatar  -->
              <b-field class="avatarSelector mb-3">
                <b-radio v-model="avatar" name="avatar" v-for="(av, i) in $store.state.avatars" :key="i" :native-value="i">
                  <div class="playerAvatar" :class="{ selected: i == avatar }" :style="{ backgroundColor: `#${color}` }">
                    <img :src="av.image.sizes.thumbnail.url" />
                  </div>
                </b-radio>
              </b-field>

              <!-- color -->
              <b-field class="mb-5">
                <input type="range" min="0" max="359" step="1" value="50" class="slider" id="hueSlider" v-model="hueVal">
              </b-field>
            </div>

            <!-- username -->
            <b-field>
              <b-input
                v-model="username"
                :placeholder="$t('account.usernamePlaceholder')"
                maxlength="25"
                expanded
                :autofocus="true"
                :class="[{'border-radius': !sugestRandomUsername}, {'is-danger': !validUsername}]"
                ref="usernameField" 
                @blur="disableWarning = false"
                :loading="fetchingRandomUsername"
                /> 
                <!-- El regex valida: que el texto no comience ni termine con espacios, que no contenga caracteres especiales y que contenga al menos 2 letras -->
                <!-- TODO Cambiar setCustomValidity para que sea traducible -->
              <p class="control">
                <b-button v-if="sugestRandomUsername" type="is-primary" @click="Randomize()">
                  <d-icon icon="FaShuffle" size="is-small"/>
                </b-button>
              </p>
            </b-field>

            
          </div>

        </b-step-item>

        <b-step-item v-if="showExtraFields">
          <p class="pb-2" v-if="$store.state.setup.registration.callToAction">{{$store.state.setup.registration.callToAction}}</p>
          <user-extra-fields ref="extraFields" />
        </b-step-item>

      </b-steps>

      <b-button
          :label="playButtonLabel"
          class="mt-3"
          type="is-primary"
          expanded
          :disabled="ValidateUsername()"
          @click="Register()" />
          <p class="help is-danger" v-show="!validUsername">{{$t('account.invalidUsernameMessage')}}</p>

      <div class="m-3 has-text-centered">
        {{$t('account.alreadyHaveAccount')}}
        <a @click="GoToLogin()" class="goLink has-text-black-bis has-text-weight-bold">
          {{$t('account.login')}}</a>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { hsv2hex } from "color-functions"
import UserExtraFields from "../components/account/UserExtraFields";

const ACCOUNTSTATE = Object.freeze({
  inactive: 0,
  login: 1,
  register: 2,
  verify: 3,
});

export default {
  components: { UserExtraFields },
  props: {},
  data() {
    return {
      sugestRandomUsername: false, 
      username: "",
      fakeUsername: "",
      acceptTerms: false,
      avatar: 0,
      color: "fcfcf0",
      hueVal: 100,
      fetchingRandomUsername: false,
      hideHtml: true,
      regexValidation: /(?=.*[a-zA-Z].*[a-zA-Z])^[a-zA-Z0-9\s\u00C0-\u024FñÑáéíóúÁÉÍÓÚ]+$/g,
      disableWarning: true,
      // Steps
      activeStep: 0,
    };
  },
  computed: {
    showExtraFields(){
      return this.$store.state.setup.registration.limitUseWithoutRegistration && this.$store.state.setup.registration.guestMaxExperiences == 0;
    },
    ShowMe() {
      return this.$store.state.user.accountState == ACCOUNTSTATE.register;
    },
    createdUsername() {
      return this.username.length > 1;
    },
    playButtonLabel() {
      if(!this.sugestRandomUsername){
        return this.$t('account.buttonPlayAsUsername', [this.username])
      }
      return this.createdUsername ? this.$t('account.buttonPlayAsUsername', [this.username]) : this.$t('account.buttonPlayGuest');
    },
    validUsername() {
      return this.disableWarning || this.username.match(this.regexValidation) 
    },
  },
  methods: {
    async FetchRandomUsername(){
      return new Promise((resolve) => {
        if (!this.sugestRandomUsername){
          resolve();
          return;
        }
        this.fetchingRandomUsername = true
        axios.get(process.env.VUE_APP_API_BASE + `/api/globals/setup/randomusername`)
          .then(res => {
            this.username = res.data.username
            this.fetchingRandomUsername = false
            resolve();
          })
          .catch(err => {
            console.warn(err)
            setTimeout(() => {
              this.FetchRandomUsername()
            }, 1500);
          });
      })
    },
    clearIconClick(){
      this.username = ""
    },
    ValidateUsername(){
      return !this.username.match(this.regexValidation) 
    },
    Register() {
      if(this.ValidateUsername()) return
      if(this.showExtraFields){
        if(this.activeStep == 0){
          this.activeStep = 1;
          return;
        }else{
          if (!this.$refs.extraFields.validate()) {
            console.log("Extra fields not valid")
            return
          }
          console.log("extra fields are valid")
          this.RegisterUserWithExtraFields()
        }
      }else{
        this.RegisterGuestUser();
      }
    },
    RegisterGuestUser(){
      this.$store.dispatch("user/CreateGuestUser", { username: this.username.trim(), color: this.color, avatar: this.avatar })
    },
    async RegisterUserWithExtraFields(){
      var fields  ={ username: this.username.trim(), color: this.color, avatar: this.avatar, ...this.$refs.extraFields.RetrieveFields() }
      var requiredStep = await this.$store.dispatch("user/CreateFullUser", fields)
      this.activeStep = requiredStep;
    },
    GoToLogin() {
      // this.$store.commit("user/SetAccountState", 1); // login
      this.$router.push("/login")
    },
    Randomize() {
      return new Promise((resolve) => {
        this.FetchRandomUsername()
        .then(() => {
          this.avatar = Math.floor(Math.random() * (this.$store.state.avatars.length - 2) + 1)
  
          this.hueVal = Math.floor(Math.random() * 360)
  
          this.color = hsv2hex(
            this.hueVal,
            this.$store.getters["user/ColorSaturation"],
            this.$store.getters["user/ColorBrightness"]
          ).substring(1);
          resolve();
        })
        
      })
    }
  },
  watch: {
    hueVal(newVal) {
      newVal = parseInt(newVal)
      this.color = hsv2hex(
        newVal,
        this.$store.getters["user/ColorSaturation"],
        this.$store.getters["user/ColorBrightness"]
      ).substring(1);
    },
  },
  async beforeMount(){
    this.sugestRandomUsername = !this.$store.state.setup.registration.dontSugestRandomUsername;
    await this.Randomize()
    
    let skipUserCreation = this.$store.state.setup.userCreation?.skipUserCreation;
    let userClosedSession = localStorage.getItem("userClosedSession") == 1;
    console.log("skipUserCreation", skipUserCreation)
    console.log("userClosedSession", userClosedSession)
    if(skipUserCreation && !userClosedSession){
      this.hideHtml = true;
      // Skip user creation
      this.RegisterGuestUser();
    }

    if(userClosedSession || !skipUserCreation){
      this.hideHtml = false;
      await this.Randomize()
    }
    localStorage.setItem("userClosedSession", false);
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
.section > div {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  height: 100%;
}

.fill-strech {
  flex: 1 1 auto;
}

.goLink { 
  display: inline-block;
}
.control.is-loading::after{
  top: 13px;
}

</style>

<style lang="scss">
.b-steps {
  .step-content {
    padding: 0 !important;
  }
  .steps {
    display: none !important;
  }
}

</style>